import React, { useState, useEffect } from 'react';

function ImageView(props) {
    const [img, setImg] = useState(false);
    const url = props.url
    const fullUrl = props.baseUrl + url;
    function fileName(uri) {
        const uriParts = uri.split('/');
        return uriParts[uriParts.length - 1];
    };
    useEffect(() => {
        if (url) {
            const uriParts = url.split('.');
            let exe = uriParts[uriParts.length - 1];
            console.warn(exe);
            exe = exe.toLowerCase();
            if (exe === "jpg" || exe === "jpeg" || exe === "png" || exe === "webp") {
                setImg(true)
            }
        }
    }, []);
    return (
        <>
            {img ?
                <img src={fullUrl} className='img-fluid mx-auto rounded' />
                :
                <a href={fullUrl} target='_blank' >
                    {fileName(url)}
                </a >
            }
        </>

    );
};
export default ImageView;
