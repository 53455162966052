import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { agtApiKey, custApiKey, msgToast, getApiToken } from '../inc/myFunctions';
import API from "./axios";
import Loader from './Loader';
import Backbtn from './Backbtn';
import Top from '../pages/Top';
import Btm from '../pages/Btm';
import LeftSide from '../pages/LeftSide';
import { BsFileEarmarkRichtext } from "react-icons/bs";
import { FaUpload } from "react-icons/fa";

const FileUpload = ({ navigation, route }) => {
    const navigate = useNavigate();
    const location = useLocation();
    let pid = location.state;
    try {
        if (pid === "" || pid === null || typeof pid === "undefined") {
            msgToast("error", "Invalid Request", 'top')
            navigation.goBack();
        }
        pid = pid.pid;
    } catch (e) {
        msgToast("error", "Invalid Request", 'top')
        navigation.goBack();
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [frontFile, setFrontFile] = useState(null);
    const [backFile, setBackFile] = useState(null);
    const [otherFile, setOtherFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectFile = (e, str) => {
        console.log(str);
        if (str == "front") {
            setFrontFile(e.target.files[0]);
        }
        if (str == "back") {
            setBackFile(e.target.files[0]);
        }
        if (str == "other") {
            setOtherFile(e.target.files[0]);
        }
        setSelectedFile(e.target.files[0]);
    };

    const uploadFile = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('doc_id', pid);
            formData.append('agt_api_key', agtApiKey());
            let cust_api_key = custApiKey()
            formData.append('cust_api_key', cust_api_key);
            if (frontFile) {
                formData.append('front_side', frontFile);
            }
            if (backFile) {
                formData.append('back_side', backFile);
            }
            if (otherFile) {
                formData.append('other_side', otherFile);
            }


            try {
                setLoading(true);
                const token = await getApiToken("Yes");
                const config = { headers: { Authorization: `Bearer ` + token, 'Content-Type': 'multipart/form-data', } }
                const rs = await API.post(
                    '/customers/docs.php?api_action=UploadDocs',
                    formData,
                    config
                );
                // Handle the response from the server
                setLoading(false);
                const msg = rs.data.status_msg;
                console.log(rs.data)
                if (rs.data.status_code === 1000) {
                    if (msg === "SUCCESSFULL") {
                        msgToast("success", "File Uploaded Successfully", 'top-right')
                    } else {
                        msgToast("error", msg, 'top-right');
                        console.log(rs.data);
                    }
                    navigate(-1)
                } else {
                    msgToast("error", msg, 'top-right');
                    console.log(rs.data);
                    return;
                }
            } catch (error) {
                setLoading(false);
                msgToast('error', 'Error uploading image:' + error)
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="appMain">
            {loading && <Loader />}
            <Top />
            <div className="container-fuild app-main-card onboard-card">
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-3'>
                            <LeftSide />
                        </div>
                        <div className='col-12 col-lg-9'>
                            {/* Grid container */}
                            <div className="card">
                                <div className="card-header">
                                    Upload Docs
                                    <div className="title-box">
                                        <Backbtn />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="container p-0">
                                        <div className="row mx-auto" style={{ maxWidth: 500 }}>
                                            <div className="file-input-container mb-2 pt-3">
                                                <input
                                                    type="file"
                                                    id="front"
                                                    className="file-input"
                                                    onChange={(e) => selectFile(e, 'front')}
                                                />
                                                <label htmlFor="front" className="file-label">
                                                    <BsFileEarmarkRichtext /> Select Front Side
                                                </label>
                                                {frontFile && (
                                                    <p className="file-info"><b>Front side doc:</b> {frontFile.name}</p>
                                                )}
                                            </div>
                                            <div className="file-input-container mb-2">
                                                <input
                                                    type="file"
                                                    id="back"
                                                    className="file-input"
                                                    onChange={(e) => selectFile(e, 'back')}
                                                />
                                                <label htmlFor="back" className="file-label">
                                                    <BsFileEarmarkRichtext /> Select Back Side
                                                </label>
                                                {backFile && (
                                                    <p className="file-info"><b>Back side doc:</b> {backFile.name}</p>
                                                )}
                                            </div>
                                            <div className="file-input-container mb-2">
                                                <input
                                                    type="file"
                                                    id="other"
                                                    className="file-input"
                                                    onChange={(e) => selectFile(e, 'other')}
                                                />
                                                <label htmlFor="other" className="file-label">
                                                    <BsFileEarmarkRichtext /> Select Other Side
                                                </label>
                                                {otherFile && (
                                                    <p className="file-info"><b>Other Side doc:</b> {otherFile.name}</p>
                                                )}
                                            </div>
                                            {selectedFile && (
                                                <div className="col-12 mb-3">
                                                    <div className="input-group">
                                                        <button className="btn bg-success btn-app fw-bold" onClick={uploadFile}><FaUpload className='me-1' /> Upload</button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Grid container End*/}

                        </div>
                    </div>
                </div>
            </div>
            {/* closing app-main-card */}
            <Btm />
        </div>
    );
};

export default FileUpload;
