import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../inc/Loader';
import Onboartop from './Onboardtop';
import { BsShieldLock } from "react-icons/bs";
import { agtApiKey, getApiToken, custApiKey, msgToast } from '../inc/myFunctions';
import API from "../inc/axios";
function Otpverification() {
    const navigate = useNavigate();
    const [otPVerif, setotPVerif] = useState({
        otp_code: "",
    })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setotPVerif({ ...otPVerif, agt_api_key: agtApiKey(), cust_api_key: custApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};
        if ((otPVerif.otp_code).length != 6) {
            newErrors.otp_code = "Please Enter Valid OTP CODE";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/customers/otp.php?api_action=OTP_Verify', JSON.stringify(otPVerif), config);
            const msg = rs.data.status_msg
            if (rs.status == 200 && rs.data.status_code == 1000) {
                msgToast("success", "Successfully OTP Verified", 'top-right')
                setTimeout(function () { navigate("/") }, 1000);

            } else {
                msgToast("error", msg, 'top-right')
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right')
            console.error(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="appMain">
            {loading && <Loader />}
            <Onboartop />
            <div className="container-fuild app-main-card onboard-card">
                <div className="container mx-width">
                    <h4 className="app-title">OTP Verification</h4>
                </div>
                <div className="container p-0 mx-width">
                    <form method="POST" action="" onSubmit={handleSubmit} autoComplete="off" className="form-horizontal" id="frm_new">
                        <div className="container-fluid p-0">
                            <div className="row m-0">
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text"><BsShieldLock /></span>
                                        <input type="text" className="form-control" value={otPVerif.otp_code} onChange={handleIpunt} placeholder="Verification code" name="otp_code" />
                                    </div>
                                    {errors.otp_code && <div className="invalid-feedback" style={{ display: "block" }}>{errors.otp_code}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <button className="btn btn-primary btn-app">Enter</button>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {errors.app_error && <div className="alert alert-danger mb-3">{errors.app_error}</div>}
                                </div>
                                <div className="col-12">
                                    <div className="input-group mb-3">
                                        <Link to="/Signin" className="w-100">
                                            <button type="button" className="btn btn-primary btn-app btn-app-active">Send again OTP</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* closing app-main-card */}
        </div>

    );
}

export default Otpverification;
