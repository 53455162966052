import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../inc/Loader';
import Onboartop from './Onboardtop';
import InputMask from "react-input-mask";
// import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import LogInImg from '../images/signup.jpg';
import { BiUser, BiLockAlt, BiPhone } from "react-icons/bi";
import { IoMailOutline, IoCalendarOutline } from "react-icons/io5";
import { agtApiKey, getApiToken, passValidate, emailValidate, msgToast } from '../inc/myFunctions';
import API from "../inc/axios";
// const maskGenerator = createDefaultMaskGenerator('99-99-9999');
function Signup() {

    const navigate = useNavigate();
    const [userReg, setUserReg] = useState({
        first_name: "",
        last_name: "",
        date_of_birth: "",
        phone: "",
        cust_login_id: "",
        cust_login_pass: ""
    })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserReg({ ...userReg, agt_api_key: agtApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};
        if (!userReg.first_name) {
            newErrors.first_name = "Please Enter First Name";
        }
        if (!userReg.last_name) {
            newErrors.last_name = "Please Enter Last Name";
        }
        if (!userReg.date_of_birth) {
            newErrors.date_of_birth = "Please Enter Date of Birth";
        }
        if (!userReg.phone) {
            newErrors.phone = "Please Enter Phone / Mobile No.";
        }
        if (!emailValidate(userReg.cust_login_id)) {
            newErrors.cust_login_id = "Please Enter Valid Email";
        }
        if (!passValidate(userReg.cust_login_pass)) {
            newErrors.cust_login_pass = "Password should contain at least one special character such as @#$%^&_, at least one number,minimum 7 characters length and should use a variation of upper and lower case letters.";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            if (userReg.date_of_birth.endsWith("_")) {
                userReg.date_of_birth = userReg.date_of_birth.slice(0, -1);
            }
            const rs = await API.post('/customers/signup.php?api_action=SignUp', JSON.stringify(userReg), config);
            const msg = rs.data.status_msg;
            if (rs.status === 200 && rs.data.status_code === 1000) {
                if (msg === "SUCCESSFULL") {
                    msgToast("success", "Your account created successfully, Please sigin", 'top-right');
                } else {
                    msgToast("success", msg, 'top-right');
                }
                navigate("/Signin");

            } else {
                msgToast("error", msg, 'top-right');
                return;
            }
        } catch (error) {
            console.error(error);
            msgToast("error", "An error occurred please try again", 'top-right');
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="appMain">
            {loading && <Loader />}
            <div className="container-fuild onboard-card">
                <div className="row vh-100">
                    <div className="col-12 col-md-6  p-0">
                        <Onboartop bg={LogInImg} t1="Get Verified!" t2="Every day, Payyed makes thousands of customers happy." />
                    </div>
                    <div className="col-12 col-md-6 hero-bg2 py-5">
                        <div className='row h-100'>
                            <div className='col-11 col-lg-9 col-xl-8 m-auto p-0'>
                                <h4 className="app-title-2">Sign Up</h4>
                                <form method="POST" action="" onSubmit={handleSubmit} className="form-horizontal" autoComplete="off" id="frm_new">
                                    <div className="col-12 mb-2">
                                        <label className="over-lable">First Name</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><BiUser /></span>
                                            <input type="text" className="form-control" value={userReg.first_name} onChange={handleIpunt} placeholder="First Name" name="first_name" id="first_name" />
                                        </div>
                                        {errors.first_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.first_name}</div>}
                                    </div>
                                    <div className="col-12 mb-2">
                                        <label className="over-lable">Last Name</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><BiUser /></span>
                                            <input type="text" className="form-control" value={userReg.last_name} onChange={handleIpunt} placeholder="Last Name" name="last_name" id="last_name" />
                                        </div>
                                        {errors.last_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.last_name}</div>}
                                    </div>
                                    <div className="col-12  mb-2">
                                        <label className="over-lable">Date of Birth</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><IoCalendarOutline /></span>
                                            {/* <MaskedInput className="form-control" maskGenerator={maskGenerator} name="date_of_birth" value={userReg.date_of_birth}  onChange={handleIpunt} placeholder="DD-MM-YYYY" /> */}
                                            <InputMask className="form-control" mask="99-99-9999" name="date_of_birth" value={userReg.date_of_birth} onInput={handleIpunt} placeholder="DD-MM-YYYY" />
                                        </div>
                                        {errors.date_of_birth && <div className="invalid-feedback" style={{ display: "block" }}>{errors.date_of_birth}</div>}
                                    </div>
                                    <div className="col-12 mb-2">
                                        <label className="over-lable">Mobile / Phone</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><BiPhone /></span>
                                            <input type="text" className="form-control" value={userReg.phone} onChange={handleIpunt} placeholder="Mobile / Phone" id="phone" name="phone" />
                                        </div>
                                        {errors.phone && <div className="invalid-feedback" style={{ display: "block" }}>{errors.phone}</div>}
                                    </div>
                                    <div className="col-12 mb-2">
                                        <label className="over-lable">Email</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><IoMailOutline /></span>
                                            <input type="email" className="form-control" value={userReg.cust_login_id} onChange={handleIpunt} placeholder="Email" id="cust_login_id" name="cust_login_id" />
                                        </div>
                                        {errors.cust_login_id && <div className="invalid-feedback" style={{ display: "block" }}>{errors.cust_login_id}</div>}
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label className="over-lable">Password</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><BiLockAlt /></span>
                                            <input type="password" className="form-control" value={userReg.cust_login_pass} onChange={handleIpunt} placeholder="Password" id="cust_login_pass" name="cust_login_pass" />
                                        </div>
                                        {errors.cust_login_pass && <div className="invalid-feedback" style={{ display: "block" }}>{errors.cust_login_pass}</div>}
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="input-group">
                                            <button className="btn btn-primary btn-app">Create Account</button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                    </div>

                                    <p className="text-center">Already have an account <Link to="/Signin">Sign in</Link></p>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* closing app-main-card */}
        </div>

    );
}

export default Signup;
