import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiUser, BiHome, BiPhone } from "react-icons/bi";
import { BsMailbox } from "react-icons/bs";
import { FaCity } from "react-icons/fa";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { IoCalendarOutline } from "react-icons/io5";
import Loader from '../../inc/Loader';
import { agtApiKey, getApiToken, custApiKey, getCountryList, getOccupationList, getSOFList, getCustTitleList, dateFm, msgToast } from '../../inc/myFunctions';
import API from "../../inc/axios";
import Backbtn from '../../inc/Backbtn';

function EditProfile() {
    //const navigate = useNavigate();
    const [editprofile, seteditprofile] = useState({
        middle_name: "",
        sec_last_name: "",
        cust_type: "",
        business_name: "",
        gender: "",
        city_of_birth: "",
        country_of_birth: "",
        address_1: "",
        address_2: "",
        city: "",
        pstate: "",
        post_code: "",
        country: "",
        nationality: "",
        occupation: "",
        source_of_fund: ""
    })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [getData, setData] = useState(null);
    const [getCountry, setgetCountry] = useState(null);
    const [getOccupLIST, setgetOccupLIST] = useState(null);
    const [getSOFLIST, setgetSOFLIST] = useState(null);
    const [getCustTitleLIST, setgetCustTitleLIST] = useState(null);
    async function getCustProfileData() {
        const country = await getCountryList()
        setgetCountry(country);
        const OCCLIST = await getOccupationList();
        setgetOccupLIST(OCCLIST);
        const SOFLIST = await getSOFList();
        setgetSOFLIST(SOFLIST);
        const CUSTTITLE = await getCustTitleList();
        setgetCustTitleLIST(CUSTTITLE);
        const newErrors = {};
        const formData = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey()
        }
        try {
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const response = await API.post('/customers/profile.php?api_action=Profile', JSON.stringify(formData), config);
            if (response.status === 200 && response.data.status_code === 1000) {
                setData(true)
                const rs = response.data.cust_detail;
                seteditprofile({
                    ...editprofile,
                    cust_title: rs.cust_title,
                    first_name: rs.first_name,
                    middle_name: rs.middle_name,
                    last_name: rs.last_name,
                    sec_last_name: rs.sec_last_name,
                    cust_type: rs.cust_type,
                    business_name: rs.business_name,
                    date_of_birth: dateFm(rs.date_of_birth),
                    gender: rs.gender,
                    city_of_birth: rs.city_of_birth,
                    country_of_birth: rs.country_of_birth,
                    address_1: rs.address_1,
                    address_2: rs.address_2,
                    city: rs.city,
                    pstate: rs.pstate,
                    post_code: rs.post_code,
                    country: rs.country,
                    nationality: rs.nationality,
                    phone: rs.phone,
                    occupation: rs.occupation,
                    source_of_fund: rs.source_of_fund
                })
            } else {
                if (typeof response.data.status_msg === "undefined") {

                    console.log(response)
                    newErrors.app_error = "No Data Found";
                } else {
                    newErrors.app_error = response.data.status_msg;
                }
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        getCustProfileData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDisabled(false)
        seteditprofile({ ...editprofile, agt_api_key: agtApiKey(), cust_api_key: custApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};

        if (!editprofile.gender) {
            newErrors.gender = "Please Select Gender";
        }
        if (!editprofile.cust_title) {
            newErrors.cust_title = "Please Select Mr/Mrs";
        }
        if (!editprofile.city_of_birth) {
            newErrors.city_of_birth = "Please Enter City of Birth";
        }
        if (!editprofile.nationality) {
            newErrors.nationality = "Please Select Nationality";
        }
        if (!editprofile.source_of_fund) {
            newErrors.source_of_fund = "Please Select Source of Fund";
        }
        if (!editprofile.occupation) {
            newErrors.occupation = "Please Select Occupation";
        }
        if (!editprofile.post_code) {
            newErrors.post_code = "Please Enter Post Code";
        }
        if (!editprofile.city) {
            newErrors.city = "Please Enter City";
        }
        if (!editprofile.country) {
            newErrors.country = "Please Select Country";
        }
        if (!editprofile.address_1) {
            newErrors.address_1 = "Please Enter Address 1";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/customers/profile.php?api_action=UpProfile', JSON.stringify(editprofile), config);
            const msg = rs.data.status_msg;
            if (rs.status === 200 && rs.data.status_code === 1000) {
                if (msg === "SUCCESSFULL") {
                    msgToast("success", "Profile Updated Successfully", 'top-right')
                } else {
                    msgToast("error", msg, 'top-right')
                }
                //setTimeout(function () { navigate("/CustomerDocs") }, 1000);
            } else {
                msgToast("error", msg, 'top-right');
                console.log(rs.data);
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right')
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            {loading && <Loader />}
            <div className="card">
                <div className="card-header">
                    Profile
                    <div className="title-box">
                        {/* <Backbtn /> */}
                        <Link className="link" to="/Profile/EditProfileNumber">Change Ph #</Link>

                    </div>
                </div>
                <div className="card-body">
                    <div className="container p-0 mx-width">
                        {getData ? (
                            <form method="POST" action="" onSubmit={handleSubmit} className="form-horizontal" autoComplete="off" id="frm_new">

                                <div className="container-fluid p-0">
                                    <div className="row m-0">
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="input-group">
                                                <span className="input-group-text"><BiPhone /></span>
                                                <input type="text" className="form-control" value={editprofile.phone} disabled />
                                            </div>
                                            {errors.phone && <div className="invalid-feedback" style={{ display: "block" }}>{errors.phone}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">

                                            <select className='form-select' value={editprofile.cust_title || ""} onChange={handleIpunt} id="cust_title" name="cust_title" >
                                                <option value="">--Mr/Mrs--</option>
                                                {getCustTitleLIST && (getCustTitleLIST.map((rs, index) => (
                                                    <option key={index} value={rs.title || ""}>{rs.title}</option>))
                                                )}
                                            </select>
                                            {errors.id_country && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_country}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">First Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editprofile.first_name || ""} disabled />
                                            </div>
                                            {errors.first_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.first_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Middle Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editprofile.middle_name || ""} onChange={handleIpunt} placeholder="Middle Name" name="middle_name" />
                                            </div>
                                            {errors.middle_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.middle_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Last Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editprofile.last_name || ""} disabled />
                                            </div>
                                            {errors.last_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.last_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Second Last Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editprofile.sec_last_name || ""} onChange={handleIpunt} placeholder="Second Lasst Name" name="sec_last_name" />
                                            </div>
                                            {errors.sec_last_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.sec_last_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Business Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editprofile.business_name || ""} onChange={handleIpunt} placeholder="Business Name" name="business_name" />
                                            </div>
                                            {errors.business_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.business_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Date of Birth</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><IoCalendarOutline /></span>
                                                <input type="text" className="form-control" value={editprofile.date_of_birth || ""} disabled />
                                            </div>
                                            {errors.date_of_birth && <div className="invalid-feedback" style={{ display: "block" }}>{errors.date_of_birth}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Gender</label>
                                            <select className='form-select' value={editprofile.gender || ""} onChange={handleIpunt} id="gender" name="gender" >
                                                <option value="">--Select Gender--</option>
                                                <option value="MALE">MALE</option>
                                                <option value="FEMALE">FEMALE</option>
                                                <option value="OTHER">OTHER</option>
                                            </select>
                                            {errors.gender && <div className="invalid-feedback" style={{ display: "block" }}>{errors.gender}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">City of Birth</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><FaCity /></span>
                                                <input type="text" className="form-control" value={editprofile.city_of_birth || ""} onChange={handleIpunt} placeholder="City of Birth" name="city_of_birth" />
                                            </div>
                                            {errors.city_of_birth && <div className="invalid-feedback" style={{ display: "block" }}>{errors.city_of_birth}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Country of Birth</label>
                                            <select className='form-select' value={editprofile.country_of_birth || ""} onChange={handleIpunt} id="country_of_birth" name="country_of_birth" >
                                                <option value="">--Country of Birth--</option>
                                                {getCountry && (getCountry.map((rs, index) => (
                                                    <option key={index} value={rs.country_code}>{rs.country_name}</option>
                                                ))
                                                )}
                                            </select>
                                            {errors.country_of_birth && <div className="invalid-feedback" style={{ display: "block" }}>{errors.country_of_birth}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Address 1</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiHome /></span>
                                                <input type="text" className="form-control" value={editprofile.address_1 || ""} onChange={handleIpunt} placeholder="Address 1" id="address_1" name="address_1" />
                                            </div>
                                            {errors.address_1 && <div className="invalid-feedback" style={{ display: "block" }}>{errors.address_1}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Address 2</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiHome /></span>
                                                <input type="text" className="form-control" value={editprofile.address_2 || ""} onChange={handleIpunt} placeholder="Address 2" id="address_2" name="address_2" />
                                            </div>
                                            {errors.address_2 && <div className="invalid-feedback" style={{ display: "block" }}>{errors.address_2}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">City</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><FaCity /></span>
                                                <input type="text" className="form-control" value={editprofile.city || ""} onChange={handleIpunt} placeholder="City" name="city" />
                                            </div>
                                            {errors.city && <div className="invalid-feedback" style={{ display: "block" }}>{errors.city}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">State</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><HiOutlineBuildingLibrary /></span>
                                                <input type="text" className="form-control" value={editprofile.pstate || ""} onChange={handleIpunt} placeholder="State" id="pstate" name="pstate" />
                                            </div>
                                            {errors.pstate && <div className="invalid-feedback" style={{ display: "block" }}>{errors.pstate}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Post Code</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BsMailbox /></span>
                                                <input type="text" className="form-control" value={editprofile.post_code || ""} onChange={handleIpunt} placeholder="Post Code" id="post_code" name="post_code" />
                                            </div>
                                            {errors.post_code && <div className="invalid-feedback" style={{ display: "block" }}>{errors.post_code}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Country</label>
                                            <select className='form-select' value={editprofile.country || ""} onChange={handleIpunt} id="country" name="country" >
                                                <option value="">--Select Country--</option>
                                                {getCountry && (getCountry.map((rs, index) => (
                                                    <option key={index} value={rs.country_code}>{rs.country_name}</option>))
                                                )}
                                            </select>
                                            {errors.country && <div className="invalid-feedback" style={{ display: "block" }}>{errors.country}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Nationality</label>
                                            <select className='form-select' value={editprofile.nationality || ""} onChange={handleIpunt} id="nationality" name="nationality" >
                                                <option value="">--Select Nationality--</option>
                                                {getCountry && (getCountry.map((rs, index) => (
                                                    <option key={index} value={rs.country_code}>{rs.country_name}</option>))
                                                )}
                                            </select>
                                            {errors.nationality && <div className="invalid-feedback" style={{ display: "block" }}>{errors.nationality}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Occupation</label>
                                            <select className='form-select' value={editprofile.occupation || ""} onChange={handleIpunt} id="occupation" name="occupation" >
                                                <option value="">--Select Occupation--</option>
                                                {getOccupLIST && (getOccupLIST.map((rs, index) => (
                                                    <option key={index} value={rs.title}>{rs.title}</option>))
                                                )}
                                            </select>
                                            {errors.occupation && <div className="invalid-feedback" style={{ display: "block" }}>{errors.occupation}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Source of Fund</label>
                                            <select className='form-select' value={editprofile.source_of_fund || ""} onChange={handleIpunt} id="source_of_fund" name="source_of_fund" >
                                                <option value="">--Source of Fund--</option>
                                                {getSOFLIST && (getSOFLIST.map((rs, index) => (
                                                    <option key={index} value={rs.title}>{rs.title}</option>))
                                                )}
                                            </select>
                                            {errors.source_of_fund && <div className="invalid-feedback" style={{ display: "block" }}>{errors.source_of_fund}</div>}
                                        </div>
                                        <div className="col-12  mb-3">
                                            <div className="input-group">
                                                <button className="btn btn-primary btn-app" disabled={isDisabled}>Update</button>
                                            </div>
                                        </div>
                                        <div className="col-12  mb-3">
                                            {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            errors.app_error ? errors.app_error && (<div></div>) : (
                                <div className='row'>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditProfile;
