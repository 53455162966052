import React from 'react';
function Error404() {
    return (
      <>
       <div className="d-flex align-items-center justify-content-center vh-100 bg-app">
        <h1 className="display-1 fw-bold text-white">404</h1>
    </div>
      </>
    );

}

export default Error404;
