import React from 'react';
import Logo from '../images/logo.svg';
function Onboartop({ bg, t1, t2 }) {
  return (
    <div className="hero-wrap d-flex align-items-center h-100">
      <div className="hero-mask"></div>
      <div className="hero-bg hero-bg-scroll" style={{ backgroundImage: `url('${bg}')` }}></div>
      <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
        <div className="row g-0">
          <div className="col-10 col-lg-9 mx-auto">
            <div className="logo mt-5 mb-5 mb-md-0"> <a className="d-flex" title="Payyed - HTML Template"><img src={Logo} alt="AWS Remmit" /></a> </div>
          </div>
        </div>
        <div className="row g-0 my-auto">
          <div className="col-10 col-lg-9 mx-auto">
            <h1 className="fs-1 text-white mb-4 fw-bold">{t1}</h1>
            <p className="fs-5 text-white lh-base mb-5">{t2}</p>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Onboartop;