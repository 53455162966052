import React, { useEffect } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import Logo2 from '../images/logo2.svg';
import { BiUserPin, BiTransfer, BiHomeAlt } from "react-icons/bi";
import { TiDocumentText } from "react-icons/ti";
import Avatar from '../images/avatar.jpg';
import { chkLogin } from '../inc/myFunctions';
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import { FiLogOut } from "react-icons/fi";
function Top(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (chkLogin()) {
      navigate("/signin");
    }
  }, []);
  let page = "";
  if (props.page === "home") {
    page = "home";
  }
  return (
    <>
      <div className="container-fuild nav-header">
        <div className="container">
          <div className="top-box">
            <div className="menu-left">
              <Link to="/">
                <img className="logo" src={Logo2} alt="AWS" />
              </Link>
              <div className='menu-center d-md-flex d-none'>
                <NavLink className="link" to="/">
                  <div className="app-nav-link">
                    <span>Home</span>
                  </div>
                </NavLink>
                <NavLink className="link" to="/Beneficiary">
                  <div className="app-nav-link">
                    <span>Beneficiary</span>
                  </div>
                </NavLink>
                <NavLink className="link" to="/CustomerDocs">
                  <div className="app-nav-link">
                    <span>ID / Docs</span>
                  </div>
                </NavLink>
                <NavLink className="link" to="/Transaction">
                  <div className="app-nav-link">
                    <span>Transactions</span>
                  </div>
                </NavLink>
                <NavLink className="link" to="/Logout">
                  <div className="app-nav-link">
                    <span>Logout</span>
                  </div>
                </NavLink>
              </div>
            </div>


            <div className="menu-right">
              <Link to="/Profile" className="profile "><img src={Avatar} alt="/Profile" /></Link>
              <div className="nav-trigger d-md-none" data-bs-toggle="offcanvas" data-bs-target="#app-side-nav"><HiOutlineBars3CenterLeft /></div>
            </div>

          </div>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="app-side-nav">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Menu</h1>
          <div className="btn-close" data-bs-dismiss="offcanvas"></div>
        </div>
        <div className="offcanvas-body">
          <NavLink className="link" to="/">
            <div className="app-nav-link">
              <BiHomeAlt /><span>Home</span>
            </div>
          </NavLink>
          <NavLink className="link" to="/Beneficiary">
            <div className="app-nav-link">
              <BiUserPin /><span>Beneficiary</span>
            </div>
          </NavLink>
          <NavLink className="link" to="/CustomerDocs">
            <div className="app-nav-link">
              <TiDocumentText /><span>ID / Docs</span>
            </div>
          </NavLink>
          <NavLink className="link" to="/Transaction">
            <div className="app-nav-link">
              <BiTransfer /><span>Transactions</span>
            </div>
          </NavLink>
          <NavLink className="link" to="/Logout">
            <div className="app-nav-link">
              <FiLogOut /><span>Logout</span>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Top;