import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
function Top() {
  const navigate = useNavigate();
  localStorage.clear();
  useEffect(() => {
    localStorage.clear();
    navigate("/signin");
  }, []);
  return (
    <>
      <div className="container-fuild top-bg home-top">
        <div className="container">
          <div className="col-12 mb-3 placeholder-glow">
            <div className="input-group">
              <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Top;