import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputMask from "react-input-mask";
import { BiUser, BiPhone, BiHome } from "react-icons/bi";
import { BsBank2, BsHash, BsMailbox, BsXOctagonFill } from "react-icons/bs";
import { MdOutlineAccountBalance } from "react-icons/md";
import { FaCity } from "react-icons/fa";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { IoCalendarOutline } from "react-icons/io5";
import Loader from '../../inc/Loader';
import { agtApiKey, getApiToken, custApiKey, getCountryList, getRelationList, msgToast } from '../../inc/myFunctions';
import API from "../../inc/axios";
import Backbtn from '../../inc/Backbtn';
function EditBeneficiary(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [getData, setData] = useState(null);
    const [getCountry, setgetCountry] = useState(null);
    const [getRelation, setgetRelation] = useState(null);
    let pid = location.state;
    if (pid !== "" && pid !== null && typeof pid !== "undefined") {
        pid = pid.pid;
    }
    if (typeof props.pid != "undefined" && props.pid != null) {
        pid = props.pid
    }

    async function getbenefData(pid) {
        const country = await getCountryList()
        setgetCountry(country);
        const relation = await getRelationList();
        setgetRelation(relation);
        const newErrors = {};
        const getBenef = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey(),
            bene_id: pid
        }
        try {
            if (pid === "" || pid === null || typeof pid === "undefined") {
                newErrors.app_error = "Invalid Request";
                setErrors(newErrors);
                return;
            }
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const response = await API.post('/beneficiary/beneficiary.php?api_action=Profile', JSON.stringify(getBenef), config);
            if (response.status === 200 && response.data.status_code === 1000) {
                setData(true)
                const rs = response.data.bene_detail
                seteditBenef({
                    ...editBenef,
                    ptype: rs.ptype,
                    first_name: rs.first_name,
                    middle_name: rs.middle_name,
                    last_name: rs.last_name,
                    sec_last_name: rs.sec_last_name,
                    phone: rs.phone,
                    gender: rs.gender,
                    date_of_birth: rs.date_of_birth,
                    relationship: rs.relationship,
                    address_1: rs.address_1,
                    address_2: rs.address_2,
                    city: rs.city,
                    pstate: rs.pstate,
                    post_code: rs.post_code,
                    country: rs.country,
                    bank_name: rs.bank_name,
                    ac_title: rs.ac_title,
                    ac_no: rs.ac_no,
                })
            } else {
                if (typeof response.data.status_msg === "undefined") {

                    console.log(response)
                    newErrors.app_error = "No Data Found";
                } else {
                    newErrors.app_error = response.data.status_msg;
                }
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        if (typeof pid != "undefined" && pid != null) {
            getbenefData(pid)
        } else {
            navigate("/Beneficiary")
        }
    }, []);

    const [editBenef, seteditBenef] = useState({
        ptype: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        sec_last_name: "",
        phone: "",
        gender: "",
        date_of_birth: "",
        relationship: "",
        address_1: "",
        address_2: "",
        city: "",
        pstate: "",
        post_code: "",
        country: "",
        bank_name: "",
        ac_title: "",
        ac_no: "",
    })
    const [errors, setErrors] = useState({});
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDisabled(false)
        seteditBenef({ ...editBenef, "bene_id": pid, agt_api_key: agtApiKey(), cust_api_key: custApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleBenef = (e) => {
        if (e !== "") {
            props.transBenef(e);
        } else {
            props.transBenef('');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};
        if (!editBenef.first_name) {
            newErrors.first_name = "Please Enter First Name";
        }
        if (!editBenef.last_name) {
            newErrors.last_name = "Please Enter Last Name";
        }
        if (!editBenef.phone) {
            newErrors.phone = "Please Enter Phone / Mobile No.";
        }
        if (!editBenef.gender) {
            newErrors.gender = "Please Select Gender";
        }
        if (!editBenef.relationship) {
            newErrors.relationship = "Please Enter Relationship to Beneficiary";
        }
        if (!editBenef.address_1) {
            newErrors.address_1 = "Please Enter Address";
        }
        if (editBenef.ptype === "BANK") {
            if (!editBenef.bank_name) {
                newErrors.bank_name = "Please Enter Bank Name";
            }
            if (!editBenef.ac_title) {
                newErrors.ac_title = "Please Enter Account Title";
            }
            if (!editBenef.ac_no) {
                newErrors.ac_no = "Please Enter Account Number";
            }
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            if (editBenef.date_of_birth) {
                if (editBenef.date_of_birth.endsWith("_")) {
                    editBenef.date_of_birth = editBenef.date_of_birth.slice(0, -1);
                }
            }
            const rs = await API.post('/beneficiary/beneficiary.php?api_action=Update', JSON.stringify(editBenef), config);
            const msg = rs.data.status_msg;
            if (rs.status === 200 && rs.data.status_code === 1000) {
                if (msg === "SUCCESSFULL") {
                    msgToast("success", "Beneficiary Updated Successfully", 'top-right')
                } else {
                    msgToast("error", msg, 'top-right')
                }
                if (props.addBenef === "Transaction") {
                    handleBenef('editClose');
                } else {
                    navigate("/Beneficiary")
                }
            } else {
                msgToast("error", msg, 'top-right')
                console.log(rs.data)
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right')
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            {loading && <Loader />}
            <div className="card">
                <div className="card-header">
                    Edit Beneficiary
                    {(props.addBenef === "Transaction") ? <div className="app-title-2 close" onClick={() => handleBenef('')}><BsXOctagonFill /></div>
                        :
                        <Backbtn />
                    }
                </div>
                <div className="card-body">
                    <div className="container p-0 mx-width">
                        {errors.app_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.app_error}</div>}
                        {getData ? (
                            <form method="POST" action="" onSubmit={handleSubmit} className="form-horizontal" autoComplete="off" id="frm_new">

                                <div className="container-fluid p-0">
                                    <div className="row m-0">
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">First Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editBenef.first_name || ""} onChange={handleIpunt} placeholder="First Name" name="first_name" id="first_name" />
                                            </div>
                                            {errors.first_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.first_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Middle Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editBenef.middle_name || ""} onChange={handleIpunt} placeholder="Middle Name" name="middle_name" id="middle_name" />
                                            </div>
                                            {errors.middle_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.middle_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Last Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editBenef.last_name || ""} onChange={handleIpunt} placeholder="Last Name" name="last_name" id="last_name" />
                                            </div>
                                            {errors.last_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.last_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Second Last Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiUser /></span>
                                                <input type="text" className="form-control" value={editBenef.sec_last_name || ""} onChange={handleIpunt} placeholder="Second Last Name" name="sec_last_name" id="sec_last_name" />
                                            </div>
                                            {errors.sec_last_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.sec_last_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Mobile / Phone</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiPhone /></span>
                                                <input type="text" className="form-control" value={editBenef.phone || ""} onChange={handleIpunt} placeholder="Mobile / Phone" id="phone" name="phone" />
                                            </div>
                                            {errors.phone && <div className="invalid-feedback" style={{ display: "block" }}>{errors.phone}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Gender</label>
                                            <select className="form-select" value={editBenef.gender || ""} onChange={handleIpunt} name="gender" id="gender">
                                                <option value="">--Select Gender--</option>
                                                <option value="MALE">MALE</option>
                                                <option value="FEMALE">FEMALE</option>
                                                <option value="OTHER">OTHER</option>
                                            </select>
                                            {errors.gender && <div className="invalid-feedback" style={{ display: "block" }}>{errors.gender}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Date of Birth</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><IoCalendarOutline /></span>
                                                <InputMask type="text" className="form-control" mask="99-99-9999" name="date_of_birth" value={editBenef.date_of_birth || ""} onInput={handleIpunt} placeholder="DD-MM-YYYY" />
                                            </div>
                                            {errors.date_of_birth && <div className="invalid-feedback" style={{ display: "block" }}>{errors.date_of_birth}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Relationship</label>
                                            <select className='form-select' value={editBenef.relationship || ""} onChange={handleIpunt} id="relationship" name="relationship" >
                                                <option value="">--Select Relationship--</option>
                                                {getRelation && (getRelation.map((rs, index) => (
                                                    <option key={index} value={rs.title}>{rs.title}</option>))
                                                )}
                                            </select>
                                            {errors.relationship && <div className="invalid-feedback" style={{ display: "block" }}>{errors.relationship}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Address</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiHome /></span>
                                                <input type="text" className="form-control" value={editBenef.address_1 || ""} onChange={handleIpunt} placeholder="Address" id="address_1" name="address_1" />
                                            </div>
                                            {errors.address_1 && <div className="invalid-feedback" style={{ display: "block" }}>{errors.address_1}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Address 2</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BiHome /></span>
                                                <input type="text" className="form-control" value={editBenef.address_2 || ""} onChange={handleIpunt} placeholder="Address 2" id="address_2" name="address_2" />
                                            </div>
                                            {errors.address_2 && <div className="invalid-feedback" style={{ display: "block" }}>{errors.address_2}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">City Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><FaCity /></span>
                                                <input type="text" className="form-control" value={editBenef.city || ""} onChange={handleIpunt} placeholder="City Name" id="city" name="city" />
                                            </div>
                                            {errors.city && <div className="invalid-feedback" style={{ display: "block" }}>{errors.city}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">State</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><HiOutlineBuildingLibrary /></span>
                                                <input type="text" className="form-control" value={editBenef.pstate || ""} onChange={handleIpunt} placeholder="State" id="pstate" name="pstate" />
                                            </div>
                                            {errors.pstate && <div className="invalid-feedback" style={{ display: "block" }}>{errors.pstate}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Post Code</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BsMailbox /></span>
                                                <input type="text" className="form-control" value={editBenef.post_code || ""} onChange={handleIpunt} placeholder="Post Code" id="post_code" name="post_code" />
                                            </div>
                                            {errors.post_code && <div className="invalid-feedback" style={{ display: "block" }}>{errors.post_code}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Country</label>
                                            <select className='form-select' value={editBenef.country || ""} onChange={handleIpunt} id="country" name="country" >
                                                <option value="">--Select Country--</option>
                                                {getCountry && (getCountry.map((rs, index) => (
                                                    <option key={index} value={rs.country_code}>{rs.country_name}</option>))
                                                )}
                                            </select>
                                            {errors.country && <div className="invalid-feedback" style={{ display: "block" }}>{errors.country}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Bank Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BsBank2 /></span>
                                                <input type="text" className="form-control" value={editBenef.bank_name || ""} onChange={handleIpunt} placeholder="Bank Name" id="bank_name" name="bank_name" />
                                            </div>
                                            {errors.bank_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.bank_name}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Account Title</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><MdOutlineAccountBalance /></span>
                                                <input type="text" className="form-control" value={editBenef.ac_title || ""} onChange={handleIpunt} placeholder="Account Title" id="ac_title" name="ac_title" />
                                            </div>
                                            {errors.ac_title && <div className="invalid-feedback" style={{ display: "block" }}>{errors.ac_title}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="outerLable">Account Number</label>
                                            <div className="input-group">
                                                <span className="input-group-text"><BsHash /></span>
                                                <input type="text" className="form-control" value={editBenef.ac_no || ""} onChange={handleIpunt} placeholder="Account Number" id="ac_no" name="ac_no" />
                                            </div>
                                            {errors.ac_no && <div className="invalid-feedback" style={{ display: "block" }}>{errors.ac_no}</div>}
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="input-group">
                                                <button className="btn btn-primary btn-app" disabled={isDisabled}>Submit</button>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            errors.app_error ? errors.app_error && (<div></div>) : (
                                <>
                                    <div className="col-12 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3 placeholder-glow">
                                        <div className="input-group">
                                            <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditBeneficiary;
