import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
function Top(props) {
  const currentYear = new Date().getFullYear();
  return (
    <div className="container-fuild">
      <div className="container">
        <div className='row'>
          <div className='col-6'> <p className='footer'>Copyright © {currentYear} <b className='color'>AWSREMIT</b>.</p></div>
          <div className='col-6'><p className='footer text-end'>Devolped By <Link className='color' to="https://activewebsol.com/" target="_blank" rel="noopener noreferrer">Active Web Solutions</Link></p></div>
        </div>
      </div>
    </div>
  );
}

export default Top;