import React, { useState, useEffect } from 'react';
import { agtApiKey, getApiToken, custApiKey } from '../inc/myFunctions';
import { AiOutlineExclamation } from "react-icons/ai";
import Avatar from '../images/avatar.jpg';
import API from "../inc/axios";
function Top(props) {
  const [errors, setErrors] = useState({});
  const [custAlert, setCustAlert] = useState(null);
  async function checkAlerts() {
    const newErrors = {};
    const formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    try {
      const token = await getApiToken("Yes");
      const config = { headers: { Authorization: `Bearer ` + token } }
      const rsb = await API.post('/transactions/cust_alerts.php?api_action=CustAlerts', JSON.stringify(formData), config);
      if (rsb.status === 200 && rsb.data.status_code === 1000) {
        const rs = rsb.data;
        const newErrors = {};
        if (typeof rs.cust_alerts != "undefined") {
          setCustAlert(rs.cust_alerts.docs_alert);
          setErrors(newErrors);
        } else {
          setErrors({})
        }
      }
    } catch (error) {
      console.error(error);
      newErrors.benificiary_error = "Please Try Again";
      setErrors(newErrors);
    }
  }
  useEffect(() => {
    checkAlerts();
  }, []);
  return (
    <div className='leftSide'>
      <div className="app-box">
        <div className="profile-thumb mt-3 mb-4"> <img className="rounded-circle" src={Avatar} alt="" />
          <div className="profile-thumb-edit bg-primary text-white" data-bs-toggle="tooltip" title="" data-bs-original-title="Change Profile Picture"> <i className="fas fa-camera position-absolute"></i>

          </div>
        </div>
        <p className="text-3 fw-500 mb-2">{localStorage.getItem("email")}</p>
        <p className="mb-2"><a href="settings-profile.html" className="text-5 text-light" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit Profile" aria-label="Edit Profile"><i className="fas fa-edit"></i></a></p>
      </div>
      {custAlert &&
        <div className="app-alert-box">
          <div className="app-alert-icon"><AiOutlineExclamation /></div>
          <p className="text">{custAlert.map((rs, index) => (<span key={index} className="badge bg-danger me-1 mb-1">{rs}</span>))}</p>
        </div>
      }

    </div>
  );
}

export default Top;