import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsXOctagonFill } from "react-icons/bs";
import { agtApiKey, getApiToken, custApiKey } from '../../inc/myFunctions';
import API from "../../inc/axios";
import Backbtn from '../../inc/Backbtn';

function ViewBeneficiary(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [getData, setData] = useState(null);
    const pid = location.state;
    async function getbenefData(pid) {
        const newErrors = {};
        const getBenef = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey(),
            bene_id: pid
        }
        try {
            if (pid == "" || pid == null || typeof pid == "undefined") {
                newErrors.app_error = "Invalid Request";
                setErrors(newErrors);
                navigate("/Beneficiary")
                return;
            }
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const response = await API.post('/beneficiary/beneficiary.php?api_action=Profile', JSON.stringify(getBenef), config);
            if (response.status == 200 && response.data.status_code == 1000) {
                const rs = response.data.bene_detail
                setData(rs)
            } else {
                if (typeof response.data.status_msg == "undefined") {
                    console.log(response)
                    newErrors.app_error = "No Data Found";
                } else {
                    newErrors.app_error = response.data.status_msg;
                }
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    const handleBenef = (e) => {
        if (e !== "") {
            props.transBenef(e);
        } else {
            props.transBenef('');
        }
    };
    useEffect(() => {
        if (typeof pid !== "undefined" && pid !== null) {
            getbenefData(location.state.pid)
        } else {
            if (typeof props.pid !== "undefined" && props.pid !== null) {
                getbenefData(props.pid)
            } else {
                navigate("/Beneficiary");
            }
        }
    }, []);

    return (
        <div className="card">
            <div className="card-header">
                Beneficiary Detials
                {(props.addBenef === "Transaction") ? <div className="app-title-2 close" onClick={() => handleBenef('')}><BsXOctagonFill /></div>
                    :
                    <Backbtn />
                }
            </div>
            <div className="card-body">
                <div className="container p-0 mx-width">
                    {errors.app_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.app_error}</div>}
                    {getData ? (
                        <div className="container-fluid p-0">
                            <div className="row m-0">
                                <div className="col-12  mb-3">
                                    <ul className="list-group list-group-flush app-list">
                                        {/* <li className="list-group-item"><p>Pay Type</p><p>{getData.ptype}</p></li> */}
                                        <li className="list-group-item"><p>First Name</p><p>{getData.first_name}</p></li>
                                        <li className="list-group-item"><p>Last Name</p><p>{getData.middle_name}</p></li>
                                        <li className="list-group-item"><p>Middle Name</p><p>{getData.last_name}</p></li>
                                        <li className="list-group-item"><p>Second Last Name</p><p>{getData.sec_last_name}</p></li>
                                        <li className="list-group-item"><p>Phone</p><p>{getData.phone}</p></li>
                                        <li className="list-group-item"><p>Gender</p><p>{getData.gender}</p></li>
                                        <li className="list-group-item"><p>Date of Birth</p><p>{getData.date_of_birth}</p></li>
                                        <li className="list-group-item"><p>Relationship</p><p>{getData.relationship}</p></li>
                                        <li className="list-group-item"><p>Address 1</p><p>{getData.address_1}</p></li>
                                        <li className="list-group-item"><p>Address 2</p><p>{getData.address_1}</p></li>
                                        <li className="list-group-item"><p>City</p><p>{getData.city}</p></li>
                                        <li className="list-group-item"><p>State</p><p>{getData.pstate}</p></li>
                                        <li className="list-group-item"><p>Country</p><p>{getData.country}</p></li>
                                        <li className="list-group-item"><p>Bank Name</p><p>{getData.bank_name}</p></li>
                                        <li className="list-group-item"><p>Account Title</p><p>{getData.ac_title}</p></li>
                                        <li className="list-group-item"><p className="w-100 mb-2">Account No / IBAN #</p><p className="w-100">{getData.ac_no}</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        errors.app_error ? errors.app_error && (<div></div>) : (
                            <>
                                <div className="col-12 mb-3 placeholder-glow">
                                    <div className="input-group">
                                        <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 placeholder-glow">
                                    <div className="input-group">
                                        <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 placeholder-glow">
                                    <div className="input-group">
                                        <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 placeholder-glow">
                                    <div className="input-group">
                                        <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 placeholder-glow">
                                    <div className="input-group">
                                        <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        </div>

    );
}

export default ViewBeneficiary;
