import API from "./axios.jsx";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SWEETALERT = withReactContent(Swal);
export function msgAlert(icon, title, msg) {
  //icons='error','info','success'
  SWEETALERT.fire({
    icon: icon,
    title: title,
    text: msg,
  })
}
export function msgToast(icon, title, position) {
  var Toast = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  Toast.fire({
    icon: icon,
    title: title
  })
}
export function agtApiKey() {
  return "$FLBIwBj3AkuD3zKFOR.t2e7X";
}
export async function setApiToken() {
  try {
    let formData = {
      "agt_api_id": "agt996543",
      "agt_api_key": agtApiKey()
    }
    const res = await API.post("/token.php?api_action=Token", JSON.stringify(formData));
    if (res.status === 200 && res.data.status_code === 1000) {
      localStorage.setItem("token", btoa(res.data.api_token))
      return true;
    } else {
      console.error(res.data.status_msg)
      return false;
    }
  } catch (error) {
    console.error(error.message);
    return false;
  }
};
export async function getApiToken(isNew = "") {
  try {
    // getting new token
    if (isNew === "Yes") {
      const token = await setApiToken();
      if (token) {
        let api_token = localStorage.getItem("token");
        if (typeof api_token !== "undefined" && api_token !== "") {
          return atob(api_token);
        }
      } else {
        return false;
      }
    } else {
      //using exsting token
      let api_token = localStorage.getItem("token");
      if (typeof api_token !== "undefined" && api_token !== "") {
        return atob(api_token);
      } else {
        return false;
      }
    }
  } catch (error) {
    console.error(error.message);
  }
};
export function custApiKey() {
  let cust_token = localStorage.getItem("cust_token");
  if (typeof cust_token !== "undefined" && cust_token !== "" && cust_token !== null) {
    return atob(cust_token);
  } else {
    return false;
  }
};
export function passValidate(pass) {
  var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{7,}$/;
  return passwordRegex.test(pass);
}
export function emailValidate(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function chkLogin() {
  if (agtApiKey() === "") {
    return true;
  }
  if (!custApiKey() || typeof custApiKey() === "undefined") {
    return true;
  }
}
export function strToArray(str) {
  if (str !== "") {
    return str.split("||");
  } else {
    return "";
  }
}
export function dateFm(date) {
  try {
    if (date === "") {
      return "";
    } else {
      const d = date.split("-");
      return d[2] + "-" + d[1] + "-" + d[0]
    }
  } catch (e) {
    console.log(e)
    return "";
  }
}
export function isApp() {
  var platform = ["Android", "iOS"];

  for (var i = 0; i < platform.length; i++) {

    if (window.navigator.userAgent.indexOf(platform[i]) > - 1) {

      return true;
    }
  }
  return false;
}
export async function getCountryList() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=CountryList', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.country_list;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "country_name": "No Data Found", "country_code": "", "curr_code": "", "phone_code": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "country_name": "No Data Found", "country_code": "", "curr_code": "", "phone_code": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "country_name": "No Data Found", "country_code": "", "curr_code": "", "phone_code": "" }];
  }
}

export async function getRelationList() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=Relationship', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.relationship;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "No Data Found" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "No Data Found" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "No Data Found" }];
  }
}


export async function getIDList() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=idList', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.id_list;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "id_name": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "id_name": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "id_name": "" }];
  }
}

export async function getOccupationList() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=Occupation', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.occupation;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}

export async function getSOFList() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=SOF', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.sof;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}

export async function getCUstTypeList() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=CustType', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.cust_type;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}
export async function getCustTitleList() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=CustTitle', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.cust_title;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}

export async function getBaseCur() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/transactions/base_currency.php?api_action=getBaseCurr', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.base_curr;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}

export async function getPayCountries(pay_in_curr) {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey(),
      pay_in_curr: pay_in_curr
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/transactions/payout_country.php?api_action=getPayCountries', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.pay_out_country;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}

export async function getPayMode(base_curr, pay_out_country) {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey(),
      base_curr: base_curr,
      pay_out_country: pay_out_country
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/transactions/rates.php?api_action=getPayMode', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.payout_mode;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}
export async function getCompany(payout_country, pay_type) {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey(),
      payout_country: payout_country,
      pay_type: pay_type
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/transactions/payout_company.php?api_action=getCompany', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.comp_list;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}
export async function getRate(base_curr, pay_out_country, pay_out_curr, payout_mode, pay_out_comp_id) {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey(),
      base_curr: base_curr,
      pay_out_country: pay_out_country,
      pay_out_curr: pay_out_curr,
      payout_mode: payout_mode,
      pay_out_comp_id: pay_out_comp_id

    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/transactions/rates.php?api_action=getRate', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      let rs = response.data;

      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}

export async function transPurpose() {
  try {
    let formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    const token = await getApiToken("Yes");
    const config = { headers: { Authorization: `Bearer ` + token } }
    const response = await API.post('/settings.php?api_action=TransPurpose', JSON.stringify(formData), config);
    if (response.status === 200 && response.data.status_code === 1000) {
      const rs = response.data.purposes;
      if (typeof rs === "undefined" || rs === "") {
        console.log(response)
        return [{ "title": "" }];
      } else {
        return rs;
      }
    } else {
      console.log(response)
      return [{ "title": "" }];
    }
  } catch (error) {
    console.log(error.message);
    return [{ "title": "" }];
  }
}