import React, { useState, useEffect } from "react";
import { RiWifiOffLine } from "react-icons/ri";
function InternetPopup() {
    const [isOffline, setisOffline] = useState(!navigator.onLine);
    useEffect(() => {
      function onlineHandler() {
            setisOffline(false);
      }
      function offlineHandler() {
            setisOffline(true);
      }
      window.addEventListener("online", onlineHandler);
      window.addEventListener("offline", offlineHandler);
      return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
      };
    }, []);
    return (
      <div>
            {isOffline && 
            <div className="offlinePopup">
                <div className="internetBox">
                    <RiWifiOffLine />
                    <h4>Oh No!</h4>
                    <p>No internet connection found.</p>
                    <p>Check you connection and try again</p>
                </div>
            </div>
            }
      </div>
    );
}

export default InternetPopup;