import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { BsHash } from "react-icons/bs";
import { GoIssueClosed } from "react-icons/go";
import { TbListDetails } from "react-icons/tb";
 import InputMask from "react-input-mask";
//import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { IoCalendarOutline } from "react-icons/io5";
import Loader from '../../inc/Loader';
import { agtApiKey, getApiToken, custApiKey, getCountryList, getIDList, dateFm, msgToast } from '../../inc/myFunctions';
import API from "../../inc/axios";
//const maskGenerator = createDefaultMaskGenerator('99-99-9999');
function EditTransaction() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [editcustDocs, seteditcustDocs] = useState({
        id_type: "",
        id_no: "",
        id_issue_by: "",
        id_country: "",
        id_issue_date: "",
        id_expiry_date: "",
        address_1: "",
        id_detail: ""
    })
    const [errors, setErrors] = useState({});
    const [getData, setData] = useState(null);
    const [getCountry, setgetCountry] = useState(null);
    const [getIDLIST, setgetIDLIST] = useState(null);
    const pid = location.state;
    async function getCustDocsData(pid) {
        const country = await getCountryList()
        setgetCountry(country);
        const IDLIST = await getIDList();
        setgetIDLIST(IDLIST);
        const newErrors = {};
        const formData = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey(),
            doc_id: pid
        }
        try {
            if (pid === "" || pid === null || typeof pid === "undefined") {
                newErrors.app_error = "Invalid Request";
                setErrors(newErrors);
                return;
            }
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const response = await API.post('/customers/docs.php?api_action=DocDetail', JSON.stringify(formData), config);
            if (response.status === 200 && response.data.status_code === 1000) {
                setData(true)
                const rs = response.data.doc_detail
                seteditcustDocs({
                    ...editcustDocs,
                    id_type: rs.id_type,
                    id_no: rs.id_no,
                    id_issue_by: rs.id_issue_by,
                    id_country: rs.id_country,
                    id_issue_date: dateFm(rs.id_issue_date),
                    id_expiry_date: dateFm(rs.id_expiry_date),
                    id_detail: rs.id_detail,
                })
            } else {
                if (typeof response.data.status_msg === "undefined") {

                    console.log(response)
                    newErrors.app_error = "No Data Found";
                } else {
                    newErrors.app_error = response.data.status_msg;
                }
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        if (typeof pid != "undefined" && pid != null) {
            getCustDocsData(location.state.pid)
        } else {
            navigate("/CustomerDocs")
        }

    }, []);

    
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDisabled(false)
        seteditcustDocs({ ...editcustDocs, "doc_id": pid.pid, agt_api_key: agtApiKey(), cust_api_key: custApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};
        if (!editcustDocs.id_type) {
            newErrors.id_type = "Please Select ID Type";
        }
        if (!editcustDocs.id_no) {
            newErrors.id_no = "Please Enter ID Number";
        }
        if (!editcustDocs.id_issue_date) {
            newErrors.id_issue_date = "Please Enter ID Issue Date";
        }
        if (!editcustDocs.id_expiry_date) {
            newErrors.id_expiry_date = "Please Enter ID Expiry Date";
        }
        if (!editcustDocs.id_country) {
            newErrors.id_country = "Please Select ID Country";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            if (editcustDocs.id_issue_date.endsWith("_")) {
                editcustDocs.id_issue_date = editcustDocs.id_issue_date.slice(0, -1);
            }
            if (editcustDocs.id_expiry_date.endsWith("_")) {
                editcustDocs.id_expiry_date = editcustDocs.id_expiry_date.slice(0, -1);
            }
            console.log(JSON.stringify(editcustDocs))
            const rs = await API.post('/customers/docs.php?api_action=Update', JSON.stringify(editcustDocs), config);
            const msg = rs.data.status_msg;
            if (rs.status === 200 && rs.data.status_code === 1000) {
                if (msg === "SUCCESSFULL") {
                    msgToast("success", "Docs Updated Successfully", 'top-right')
                } else {
                    msgToast("error", msg, 'top-right')
                }
                setTimeout(function () { navigate("/CustomerDocs") }, 1000);
            } else {
                msgToast("error", msg, 'top-right')
                console.log(rs.data)
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right')
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
        {loading && <Loader />}
        <div className="container-fuild app-main-card onboard-card">
            <div className="container mx-width">
                <h4 className="app-title">Edit ID</h4>
            </div>
            <div className="container p-0 mx-width">
                {errors.app_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.app_error}</div>}
                {getData ? (
                    <form method="POST" action="" onSubmit={handleSubmit} className="form-horizontal" autoComplete="off" id="frm_new">

                        <div className="container-fluid p-0">
                            <div className="row m-0">
                            <div className="col-12 mb-3">
                                <select className='form-select' value={editcustDocs.id_type} onChange={handleIpunt} id="id_type" name="id_type" >
                                    <option value="">--Select ID Type--</option>
                                    {getIDLIST && (getIDLIST.map(rs => (
                                        <>
                                            <option value={rs.id_name}>{rs.id_name}</option>
                                        </>))
                                    )}
                                </select>
                                {errors.id_type && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_type}</div>}
                            </div>
                            <div className="col-12  mb-3">
                                <div className="input-group">
                                    <span className="input-group-text"><BsHash /></span>
                                    <input type="text" className="form-control" value={editcustDocs.id_no} onChange={handleIpunt} placeholder="ID Number" name="id_no" id="id_no" />
                                </div>
                                {errors.id_no && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_no}</div>}
                            </div>
                            <div className="col-12 mb-3">
                                <div className="input-group">
                                    <span className="input-group-text"><GoIssueClosed /></span>
                                    <input type="text" className="form-control" value={editcustDocs.id_issue_by} onChange={handleIpunt} placeholder="ID Issue by" name="id_issue_by" id="id_issue_by" />
                                </div>
                                {errors.id_issue_by && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_issue_by}</div>}
                            </div>
                            <div className="col-12 mb-3">
                                <select className='form-select' value={editcustDocs.id_country} onChange={handleIpunt} id="id_country" name="id_country" >
                                    <option value="">--Select ID Country--</option>
                                    {getCountry && (getCountry.map(rs => (
                                        <>
                                            <option value={rs.country_code}>{rs.country_name}</option>
                                        </>))
                                    )}
                                </select>
                                {errors.id_country && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_country}</div>}
                            </div>
                            <div className="col-12  mb-3">
                                <div className="input-group">
                                    <span className="input-group-text"><IoCalendarOutline /></span>
                                    <InputMask type="text" className="form-control" mask="99-99-9999" name="id_issue_date" value={editcustDocs.id_issue_date} onInput={handleIpunt} placeholder="DD-MM-YYYY" />
                                </div>
                                {errors.id_issue_date && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_issue_date}</div>}
                            </div>
                            <div className="col-12  mb-3">
                                <div className="input-group">
                                    <span className="input-group-text"><IoCalendarOutline /></span>
                                    {/* <MaskedInput maskGenerator={maskGenerator} value={value} onChange={setValue}/> */}
                                    <InputMask  className="form-control" mask="99-99-9999" name="id_expiry_date" value={editcustDocs.id_expiry_date} onInput={handleIpunt} placeholder="DD-MM-YYYY" />
                                </div>
                                {errors.id_expiry_date && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_expiry_date}</div>}
                            </div>
                            <div className="col-12 mb-3">
                                <div className="input-group">
                                    <span className="input-group-text"><TbListDetails /></span>
                                    <input type="text" className="form-control" value={editcustDocs.id_detail} onChange={handleIpunt} placeholder="ID Details" id="id_detail" name="id_detail" />
                                </div>
                                {errors.id_detail && <div className="invalid-feedback" style={{ display: "block" }}>{errors.id_detail}</div>}
                            </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <button className="btn btn-primary btn-app" disabled={isDisabled}>Submit</button>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                </div>
                            </div>
                        </div>
                    </form>
                ) : (
                    errors.app_error ? errors.app_error && (<div></div>) : (
                        <>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                        </>
                    )
                )}
            </div>
        </div>
        </>
    );
}

export default EditTransaction;
