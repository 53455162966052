import React from 'react';
import { Outlet } from 'react-router-dom';
import Top from '../Top';
import LeftSide from '../LeftSide';
import Btm from '../Btm'
function CustomerDocs() {
    return (
        <div className="appMain">
            <Top />
            <div className="container-fuild app-main-card onboard-card">
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-3'>
                            <LeftSide />
                        </div>
                        <div className='col-12 col-lg-9'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
            <Btm />
        </div>

    );
}

export default CustomerDocs;
