import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiSearch, BiEdit, BiShow, BiUpload } from "react-icons/bi";
import { agtApiKey, getApiToken, custApiKey, dateFm } from '../../inc/myFunctions';
import API from "../../inc/axios";

function CustomerDocsList() {
    const navigate = useNavigate();
    const [getData, setData] = useState(null);
    const [errors, setErrors] = useState({});
    const [search, setSearch] = useState({});
    async function getbenefData() {
        const newErrors = {};
        const getBenef = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey()
        }
        try {
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/customers/docs.php?api_action=DocList', JSON.stringify(getBenef), config);
            if (rs.status === 200 && rs.data.status_code === 1000) {
                const res = rs.data.doc_detail;
                if (typeof res === "undefined") {
                    newErrors.app_error = "Please Add Documents";
                    setErrors(newErrors);
                    console.log(rs.data)
                } else {
                    setData(res)
                }
            } else {
                console.log(rs.data)
                newErrors.app_error = rs.data.status_msg;
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        getbenefData()
    }, []);
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearch({ ...search, [name]: value })
    }
    return (
        <>
            <div className="card">
                <div className="card-header">
                    ID List
                    <div className="title-box">
                        <Link className="link" to="/CustomerDocs/NewCustomerDocs">Add New</Link>
                    </div>

                </div>
                <div className="card-body">
                    <div className="container p-0 mx-width">
                        <div className="row m-0">
                            {errors.app_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.app_error}</div>}
                            {
                                getData && (
                                    <div className="col-12 mb-3">
                                        <div className="input-group search">
                                            <span className="input-group-text"><BiSearch /></span>
                                            <input type="text" className="form-control" onChange={handleIpunt} placeholder="Search" name="search" id="search" />
                                        </div>
                                    </div>
                                )
                            }
                            {getData ? (getData.map(rs => (
                                <div className="col-12 col-sm-6 col-xl-4" key={rs.doc_id}>
                                    <div className="card app-card">
                                        <div className="app-card-body">
                                            <button type="button" onClick={() => navigate('/CustomerDocs/ViewCustomerDocs', { state: { pid: rs.doc_id } })} className="btn btn-app-card view btn-sm"><BiShow /></button>
                                            <button type="button" onClick={() => navigate('/CustomerDocs/EditCustomerDocs', { state: { pid: rs.doc_id } })} className="btn btn-app-card edit btn-sm"><BiEdit /></button>
                                            <button type="button" onClick={() => navigate('/FileUpload', { state: { pid: rs.doc_id } })} className="btn btn-app-card upd btn-sm"><BiUpload /></button>
                                            <h5 className="card-title">{rs.id_no}</h5>
                                            <p className='card-text'>{rs.id_type} {"("}{rs.id_list_name}{")"}</p>
                                            <p className='card-text mb-1'>
                                                {rs.id_status === "Approved"
                                                    ?
                                                    (<span className="badge bg-success me-1">{rs.id_status}</span>)
                                                    : rs.id_status === "Pending" ?
                                                        (<span className="badge bg-warning me-1">{rs.id_status}</span>)
                                                        :
                                                        (<span className="badge bg-danger me-1">{rs.id_status}</span>)
                                                }
                                            </p>
                                            <p className="card-text">{dateFm(rs.id_issue_date)} to {dateFm(rs.id_expiry_date)}</p>
                                            <p className="card-text">{rs.id_issue_by}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                            ) : (
                                errors.app_error ? errors.app_error && (<></>) : (
                                    <>
                                        <div className="col-12 placeholder-glow" >
                                            <span className="placeholder col-12 mb-4" style={{ height: "30px" }}></span>
                                        </div>
                                        <div className="col-12 col-sm-6 col-xl-4" >
                                            <div className="card app-card">
                                                <div className="app-card-body placeholder-glow">
                                                    <span className="placeholder btn-app-card-ph">Edit</span>
                                                    <span className="placeholder col-7"></span>
                                                    <span className="placeholder col-9"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-xl-4" >
                                            <div className="card app-card">
                                                <div className="app-card-body placeholder-glow">
                                                    <span className="placeholder btn-app-card-ph">Edit</span>
                                                    <span className="placeholder col-9"></span>
                                                    <span className="placeholder col-5"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-xl-4" >
                                            <div className="card app-card">
                                                <div className="app-card-body placeholder-glow">
                                                    <span className="placeholder btn-app-card-ph">Edit</span>
                                                    <span className="placeholder col-8"></span>
                                                    <span className="placeholder col-7"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-xl-4" >
                                            <div className="card app-card">
                                                <div className="app-card-body placeholder-glow">
                                                    <span className="placeholder btn-app-card-ph">Edit</span>
                                                    <span className="placeholder col-6"></span>
                                                    <span className="placeholder col-9"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerDocsList;
