import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../inc/Loader';
import Onboartop from './Onboardtop';
import { IoMailOutline } from "react-icons/io5";
import { agtApiKey, getApiToken, emailValidate, msgToast } from '../inc/myFunctions';
import LogInImg from '../images/forgot-pass.jpg';
import API from "../inc/axios";
function Forgetpass() {
    const navigate = useNavigate();
    const [resetPass, setresetPass] = useState({
        cust_login_id: ""
    })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setresetPass({ ...resetPass, agt_api_key: agtApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};
        if (!emailValidate(resetPass.cust_login_id)) {
            newErrors.cust_login_id = "Please Enter Valid Email";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/customers/forgetpass.php?api_action=PaswdReq', JSON.stringify(resetPass), config);
            const msg = rs.data.status_msg;
            if (rs.status == 200 && rs.data.status_code == 1000) {
                if (msg == "SUCCESSFULL") {
                    msgToast("success", "OTP code sent to your email id", 'top-right');
                } else {
                    msgToast("success", msg, 'top-right');
                }
                setTimeout(function () { navigate('/Setpassword', { state: { pid: resetPass.cust_login_id } }) }, 1000);

            } else {
                console.log(rs.data.status_msg)
                msgToast("error", msg, 'top-right');
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="appMain">
            {loading && <Loader />}
            <div className="container-fuild onboard-card">
                <div className="row vh-100">
                    <div className="col-12 col-md-6  p-0">
                        <Onboartop bg={LogInImg} t1="Lost your password?" t2="No worries, reset it securely to resume hassle-free money transfers." />
                    </div>
                    <div className="col-12 col-md-6 hero-bg2 py-5">
                        <div className='row h-100'>
                            <div className='col-11 col-lg-9 col-xl-8 m-auto p-0'>
                                <h4 className="app-title-2">Forget Password?</h4>
                                <form method="POST" action="" onSubmit={handleSubmit} autoComplete="off" className="form-horizontal" id="frm_new">
                                    <input type="hidden" id="frm_action" value="NEW" />
                                    <div className="col-12 mb-3">
                                        <label className="over-lable">Email</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><IoMailOutline /></span>
                                            <input type="text" className="form-control" value={resetPass.cust_login_id} onChange={handleIpunt} placeholder="Login ID" id="cust_login_id" name="cust_login_id" />
                                        </div>
                                        {errors.cust_login_id && <div className="invalid-feedback" style={{ display: "block" }}>{errors.cust_login_id}</div>}
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="input-group">
                                            <button className="btn btn-primary btn-app">Reset Password</button>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                    </div>
                                </form>
                                <p className="text-center">Back to <Link className="link" to="/Signin">Sign in</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* closing app-main-card */}
        </div>

    );
}

export default Forgetpass;
