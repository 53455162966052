import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//onboard
import Error404 from './components/pages/Error404';
import Signin from './components/pages/Signin';
import Signup from './components/pages/Signup';
import Otpverification from './components/pages/Otpverification';
import Forgetpass from './components/pages/Forgetpass';
import Setpassword from './components/pages/Setpassword';
import Home from './components/pages/Home';
import Logout from './components/pages/Logout';
// Beneficiary
import Beneficiary from './components/pages/Beneficiary/Beneficiary';
import BeneficiaryList from './components/pages/Beneficiary/BeneficiaryList';
import NewBeneficiary from './components/pages/Beneficiary/NewBeneficiary';
import EditBeneficiary from './components/pages/Beneficiary/EditBeneficiary';
import ViewBeneficiary from './components/pages/Beneficiary/ViewBeneficiary';
//CustomerDocs
import CustomerDocs from './components/pages/CustomerDocs/CustomerDocs';
import CustomerDocsList from './components/pages/CustomerDocs/CustomerDocsList';
import NewCustomerDocs from './components/pages/CustomerDocs/NewCustomerDocs';
import EditCustomerDocs from './components/pages/CustomerDocs/EditCustomerDocs';
import ViewCustomerDocs from './components/pages/CustomerDocs/ViewCustomerDocs';
//Profile
import Profile from './components/pages/Profile/Profile';
import EditProfile from './components/pages/Profile/EditProfile';
import EditProfileNumber from './components/pages/Profile/EditProfileNumber';
// Transaction
import Transaction from './components/pages/Transaction/Transaction';
import TransactionList from './components/pages/Transaction/TransactionList';
import NewTransaction from './components/pages/Transaction/NewTransaction';
import EditTransaction from './components/pages/Transaction/EditTransaction';
import ViewTransaction from './components/pages/Transaction/ViewTransaction';
//file upload
import FileUpload from './components/inc/FileUpload';

function App() {
  return (
    <>
      <BrowserRouter >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/Signup" element={<Signup />} />
          <Route exact path="/Forgetpass" element={<Forgetpass />} />
          <Route exact path="/Setpassword" element={<Setpassword />} />
          <Route exact path="/Otpverification" element={<Otpverification />} />
          <Route exact path="/Signin" element={<Signin />} />
          <Route exact path="/FileUpload" element={<FileUpload />} />
          <Route exact path="/Logout" element={<Logout />} />
          <Route exact path="/Beneficiary" element={<Beneficiary />} >
            <Route index element={<BeneficiaryList />} />
            <Route exact path="NewBeneficiary" element={<NewBeneficiary />} />
            <Route exact path="EditBeneficiary" element={<EditBeneficiary />} />
            <Route exact path="ViewBeneficiary" element={<ViewBeneficiary />} />
          </Route>
          <Route exact path="/Transaction" element={<Transaction />} >
            <Route index element={<TransactionList />} />
            <Route exact path="NewTransaction" element={<NewTransaction />} />
            <Route exact path="EditTransaction" element={<EditTransaction />} />
            <Route exact path="ViewTransaction" element={<ViewTransaction />} />
          </Route>
          <Route exact path="/CustomerDocs" element={<CustomerDocs />} >
            <Route index element={<CustomerDocsList />} />
            <Route exact path="NewCustomerDocs" element={<NewCustomerDocs />} />
            <Route exact path="EditCustomerDocs" element={<EditCustomerDocs />} />
            <Route exact path="ViewCustomerDocs" element={<ViewCustomerDocs />} />
          </Route>
          <Route exact path="/Profile" element={<Profile />} >
            <Route index element={<EditProfile />} />
            <Route exact path="EditProfileNumber" element={<EditProfileNumber />} />
          </Route>
          <Route exact path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
